import VerticalLine_ClassicVerticalSolidLineComponent from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/skinComps/BaseVerticalLine/ClassicVerticalSolidLine.skin';
import VerticalLine_ClassicVerticalSolidLineController from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/VerticalLine.controller';


const VerticalLine_ClassicVerticalSolidLine = {
  component: VerticalLine_ClassicVerticalSolidLineComponent,
  controller: VerticalLine_ClassicVerticalSolidLineController
};


export const components = {
  ['VerticalLine_ClassicVerticalSolidLine']: VerticalLine_ClassicVerticalSolidLine
};

