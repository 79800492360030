import type { PickStateRefValues } from '@wix/editor-elements-integrations';
import { withCompController } from '@wix/editor-elements-integrations';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import type { IVerticalLineProps } from '../VerticalLine.types';

export default withCompController<
  IVerticalLineProps,
  {},
  IVerticalLineProps,
  PickStateRefValues<'experiments'>
>(({ mapperProps, stateValues }) => {
  const { experiments = {} } = stateValues;
  const isClassNameToRootEnabled = isExperimentOpen(
    experiments,
    'specs.thunderbolt.isClassNameToRootEnabled',
  );

  return {
    ...mapperProps,
    isClassNameToRootEnabled,
  };
});
